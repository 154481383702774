html { height: 100%; font-size: $baseFontSize; }
	
body {
	 font-family: $baseFont;
	 font-size: $baseFontSize;
	 line-height: $baseLineHeight;
	 color: $baseFontColor;
	 background: $white;
	 font-weight: $baseFontWeight;
	 margin: 0;
	 padding: 0;
	 min-height: 100%;
	 position: relative;
	 overflow-x: hidden;
	 max-width: 100vw;
}

/* Box sizing */

*, *:after, *:before { box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }

/* Forms */

form { display: block; }
fieldset { border: none; }
label { font-weight: bold; }
input, select, button { vertical-align: top; }
a, a:hover, a:active, a:focus { outline: 0; }

/* Various */

img { max-width: 100%; height: auto; }

.last { margin-bottom: 0 !important; }

.sh-content { display: none; }


/* Alignment */

.v-top { vertical-align: top; }
.v-middle { vertical-align: middle; }
.v-bottom { vertical-align: bottom; }

/* Vertical align */

.va-wrap { display: table; width: 100%; height: 100%; }
.va-m { display: table-cell; vertical-align: middle; height: 100%; }
.va-t { display: table-cell; vertical-align: top; height: 100%; }
.va-b { display: table-cell; vertical-align: bottom; height: 100%; }

.col-set {  display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-wrap: wrap; width: 100%; }

.col2-set .col { width: 49%; margin-bottom: 1em; }
.col3-set .col { width: 32%; margin-bottom: 1em; }
.col4-set .col { width: 24%; padding: 0 0 0 1em; }

.ib { letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; }
.ib > * { display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; }


/* Layout */

@for $i from 0 through 7 {
  .m-t-#{$i} { margin-top: #{$i}em; }
  .m-b-#{$i} { margin-bottom: #{$i}em; }
  .m-l-#{$i} { margin-left: #{$i}em; }
  .m-r-#{$i} { margin-right: #{$i}em; }
  .p-t-#{$i} { padding-top: #{$i}em; }
  .p-b-#{$i} { padding-bottom: #{$i}em; }
  .p-l-#{$i} { padding-left: #{$i}em; }
  .p-r-#{$i} { padding-right: #{$i}em; }
}

.no-margin { margin: 0; }
.no-padding { padding: 0; }

.img-center { margin-left: auto; margin-right: auto; display: block; }

.p-t-sm-2 { padding-top: 2em; }

.m-b-md-4 { margin-bottom: 4em; }


@media screen and (max-width: $lg) {
	body { font-size: $baseFontSizeMd; }
}

@media screen and (max-width: $md) {
	body { font-size: $baseFontSizeSm; }

}

@media screen and (max-width: $sm) {
	body { font-size: $baseFontSizeXs; }

	.m-b-md-4 { margin-bottom: 1em; }
	.p-t-md-2 { padding-top: 0; }
}

@media screen and (max-width: $xs) {

}