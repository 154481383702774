a { color: $black; text-decoration: underline; }
a:hover, a:active { text-decoration: none; color: $yellow; }

.link-colored-underline { text-decoration: none; color: black; position: relative; z-index: 10; display: inline-block; display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; }
.link-colored-underline:before { content: ""; display: block; width: 100%; height: 2px; background: $white; position: absolute; bottom: 4px; left: -4px; z-index: -1; }
.link-colored-underline:hover { color: $white; }


/* Heading */

h1, .h1 { font-weight: 700; color: $headingColor; font-size: 3.5em; line-height: 1.3; margin: 0 0 0.5em 0; }
h2, .h2 { font-weight: 700; color: $headingColor; font-size: 3.5em; line-height: 1.2; margin: 0 0 0.35em 0; }
h3, .h3 { font-weight: 700; color: $headingColor; font-size: 2.5em; line-height: 1.3; margin: 0 0 0.35em 0; }
h4, .h4 { font-weight: 700; color: $headingColor; font-size: 1.5em; line-height: 1.5; margin: 0 0 0.5em 0; }
h5, .h5 { font-weight: 400; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 0.5em 0; }
h6, .h6 { font-weight: 400; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 0.5em 0; }

/* Paragraphs */

p { margin: 0 0 1em 0; }
address { font-style: normal; }
cite { font-style: normal; }

/* Lists */

ul, ol { list-style: none;  margin: 0 0 1em 0; }
ul li { margin: 0 0 0.25em 0; padding: 0 0 0 1em; }
ul.unstyled li { padding: 0; background: none; }

ol { counter-reset: counter; margin-left: 0; *margin-left: 54px; }
ol li { margin: 0 0 0.5em 0; padding: 0 0 0 1em; *list-style-type: decimal; counter-increment: counter; position: relative; }
ol li:before { color: $gray; content: counter(counter)'. '; position: absolute; left: 0; top: 0; }

dl { margin: 0 0 1em 0; }
dt { font-weight: bold; }

.text-small { font-size: 0.8em; }
.text-big { font-size: 1.3em; }

.text-light { font-weight: 200; }
.text-bold { font-weight: 600; }
strong { font-weight: 600; }

.text-uppercase { text-transform: uppercase; }
.text-nowrap { white-space: nowrap; }

.text-underline { text-decoration: underline; }
.text-not-underline { text-decoration: none; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.color-gray { color: $gray; }
.color-yellow { color: $yellow; }
.color-black { color: $black; }
.color-white { color: $white; }
.color-dark-yellow { color: $darkYellow; }
.color-pink { color: $pink; }

.perex p { font-size: 1.5em; }
.perex-narrow { padding: 0 8em; }

.f-l { float: left; }
.f-r { float: right; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid $gray; margin: 1em 0; padding: 0; }

sup,sub { height: 0; line-height: 1; vertical-align: baseline; _vertical-align: bottom; position: relative; }
sup { bottom: 1ex; }
sub { top: .5ex; }

.entry { margin-bottom: 40px; }
.entry h2 { margin-top: 30px; }
.entry h3 { margin-top: 30px; }
.entry h2:first-child { margin-top: 0; }
.entry h3:first-child { margin-top: 0; }
.entry ul li { position: relative; }
.entry ul li:before { content: ''; position: absolute; left: 3px; top: 12px; width: 4px; height: 4px; display: block; background: #000; }

.list-links, .list-links ul { list-style: none; }
.list-links li { position: relative; padding-left: 1.4em; margin-bottom: 1em; }
.list-links li:before { content: ""; display: block; width: 1em; height: 1em; background: url('../svg/src/icon-new-window.svg') center center no-repeat; background-size: 1em 1em; position: absolute; left: 0; top: 0.25em; }

@media screen and (max-width: $lg) {
	.perex-narrow { padding: 0 3em; }
	
	h1, .h1, h2, .h2 { font-size: 3em; }
}

@media screen and (max-width: $md) {
	.perex-narrow { padding: 0; }
}