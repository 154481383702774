@font-face{
	font-family:"TSTAR W00 Bold";
	src:url("../fonts/5161267/9ed79699-0357-4f2e-87fb-5439dce5a80a.eot?#iefix");
	src:url("../fonts/5161267/9ed79699-0357-4f2e-87fb-5439dce5a80a.eot?#iefix") format("eot"),url("../fonts/5161267/c60d890e-a82d-4eb6-bd51-f82677f5b683.woff2") format("woff2"),url("../fonts/5161267/885df0a7-4a72-49b0-bc4b-a737dc1af1b3.woff") format("woff"),url("../fonts/5161267/f1b589ee-1d06-4f0f-acbb-0a4315f9502f.ttf") format("truetype");
}

@font-face{
	font-family:"TSTAR W05 Bold";
	src:url("../fonts/5161269/5e24d4d1-8ede-4851-b443-f5782f48e106.eot?#iefix");
	src:url("../fonts/5161269/5e24d4d1-8ede-4851-b443-f5782f48e106.eot?#iefix") format("eot"),url("../fonts/5161269/fed5ec7f-2ba1-4dc3-a994-f4b926fd5d72.woff2") format("woff2"),url("../fonts/5161269/da4b81dd-6ff4-4f71-b53a-09e2d4da6711.woff") format("woff"),url("../fonts/5161269/67e8233d-14ee-43d2-94a2-a48369f8e2e4.ttf") format("truetype");
}

$baseFont: 'TSTAR W00 Bold',Arial,sans-serif;
$baseFontSize: 20px;
$baseLineHeight: 1.5;
$baseFontWeight: 300;

$white: #fff;
$black: #1d1d1b;
$gray: #949494;
$lightGray: #d2d2d1;
$lightestGray: #dfdfdf;
$yellow: #fbc43a;
$darkYellow: #977114;
$darkerYellow: #cfa334;
$pink: #e70052;

$baseFontColor: $gray;
$headingColor: $black;

$xlg: 1460px;
$lg: 1399px;
$md: 1199px;
$sm: 991px;
$xs: 767px;
$xxs: 479px;
$xxxs: 420px;

$baseFontSizeMd: 16px;
$baseFontSizeSm: 16px;
$baseFontSizeXs: 14px;