.content { padding: 0; position: relative; }

.sidenav { position: absolute; left: 0; top: 0; width: 4em; height: 100%; background: $white; z-index: 400; }
.sidenav-list { position: fixed; left: 0; top: 50%; transform: translateY(-50%); width: 4em; }
.sidenav li { display: block; margin: 0; padding: 0; list-style-type: none; }
.sidenav-link { display: block; width: 100%; height: 1.5em; position: relative; }
.sidenav-link:before { content: ""; display: block; width: 10px; height: 10px; background: $lightGray; border-radius: 50%; position: absolute; left: 50%; top: 50%; z-index: 10; transform: translate(-5px, -5px); }
.sidenav-link-text { opacity: 0; display: block; max-width: 0; visibility: hidden; padding: 0.75em 1.25em; position: absolute; background: $yellow; color: $black; top: 50%; left: 100%; transform: translateY(-50%); }
.sidenav-link-text:before { content: ""; display: block; width: 0; height: 0; border-style: solid; border-width: 0.65em 0.65em 0.65em 0; border-color: transparent $yellow transparent transparent; position: absolute; left: -0.65em; top: 50%; transform: translateY(-50%); }

.active .sidenav-link:before { background: $yellow; }
.active .sidenav-link:after { content: ""; display: block; width: 26px; height: 26px; border: 4px solid $yellow; position: absolute; left: 50%; top: 50%; border-radius: 50%; transform: translate(-13px, -13px); }
.sidenav-link:hover:before { background: $black; }
.sidenav-link:hover .sidenav-link-text { opacity: 1; width: 15em; max-width: none; visibility: visible; }


/* Block */

.block { padding: 3.5em 0; }
.block.block-narrow { padding: 1.5em 0; }

.block-yellow { background: $yellow; color: $darkYellow; }

.block-black { background: $black; }
.block-black { h1, .h1, h2, .h2, h3, .h3, h4, .h4 { color: $yellow; } }

.block-leaves { background: $black url('../img/leaves.jpg') center top repeat-y; background-size: 100% auto; color: $white; }
.block-leaves { h1, .h1, h2, .h2, h3, .h3, h4, .h4 { color: $yellow; } }

.block.block-alpha { padding-top: 0; }
.block.block-omega { padding-bottom: 0; }

.section-head { text-align: center; }

.sale { color: $black; }
.sale p { margin-bottom: 0.75em; }
.sale img { margin-top: 1.25em; }

/* Teaser */

.teaser { position: relative; padding-top: 10em; padding-bottom: 9em; background: $black; }
.teaser-bg { position: absolute; left: 50%; top: 0; width: auto; height: auto; min-width: 100%; min-height: 100%; max-width: none; transform: translateX(-50%); overflow: hidden; }
.teaser-head { font-size: 2.5em; color: $white; line-height: 1.5; position: relative; z-index: 10; }
.teaser-head .underline { position: relative; z-index: 10; display: inline-block; }
.teaser-head .underline:before { content: ""; display: block; width: 100%; height: 0.08em; background: $yellow; position: absolute; bottom: 0.14em; left: -0.1em; z-index: -1; }

.teaser:after { content: ""; display: block; width: 2px; height: 7.5em; background: $white; position: absolute; left: 50%; bottom: -2.25em; }
.teaser:before { content: ""; display: block; position: absolute; left: 50%; bottom: -2.3em; width: 10px; height: 10px; border-left: 2px solid $white; border-bottom: 0.1em solid $white; transform: rotate(-45deg); margin-left: -4px; }

.sub-teaser a:hover, .sub-teaser a:active, .sub-teaser a:focus { color: $white; }

/* Stories */

.stories { margin-top: 1.5em; }
.story-preview { width: 33.33333%; overflow: hidden; height: 16em; position: relative; }
.story-preview img { transition: transform 0.2s ease-in; margin: 0 auto; }
.story-preview .icon { position: absolute; right: 1.5em; bottom: 1.2em; width: 2em; height: 2em; fill: $white; z-index: 20; }
.story-preview:hover:after { content: ""; display: block; background: $black; width: 100%; height: 100%; position: absolute; left: 0; top: 0; opacity: 0; transition: opacity 0.2s ease-in; }
.story-preview:hover:after { opacity: 0.6; }
.story-preview:hover img { transform: scale(1.03); }
.story-content { width: 840px; }
.story-content iframe { width: 840px; height: 470px; }
.story-text { padding: 3em; }
.story-perex { color: $yellow; font-size: 1.5em; }

/* Pesticides */

.link-points-wrap { position: relative; }
.link-points { position: relative; width: 514px; height: 504px; }
.link-points-img { width: 514px; height: 504px; max-width: none; }
.link-point { position: absolute; z-index: 30; width: 36px; height: 36px; display: block; background: $yellow; border-radius: 50%; color: $black; font-size: 1.8em; text-decoration: none; line-height: 32px; text-align: center; transition: all 0.2s ease-in; }
.link-point:after { content: ""; display: block; width: 100%; height: 100%; position: absolute; left: -5px; top: -5px; border-radius: 50%; border: 5px solid rgba($white, 0.5); box-sizing: content-box; }
.link-point:hover { background: $black; color: $yellow; }
.link-point-active { background: $black; color: $white; }

.link-point-head { left: 215px; top: 5px; }
.link-point-chest { left: 200px; top: 190px; }
.link-point-stomach { left: 170px; top: 250px; }
.link-point-hips { left: 195px; top: 315px; }
.link-point-arm { left: 100px; top: 290px; }
.link-point-basket { left: 370px; top: 230px; }

.link-points-canvas { width: 771px; height: 502px; }
.canvas-wrap { position: absolute; width: 771px; height: 100%; left: 0; top: 0; z-index: 20; overflow: hidden; max-width: 100%; }
.canvas-anim { animation-name: canvasanim; animation-duration: 1s; } 
@keyframes canvasanim {
    from { width: 0; }
    to { width: 771px; }
}

.link-box { opacity: 0; right: 0; top: 2.75em; width: 260px; max-height: 0; position: absolute; background: $yellow; transition: none; z-index: 60; color: $yellow; }
.link-box h3, .link-box h4, .link-box h5 { margin-bottom: 0.15em; }
.link-box .icon { width: 0.75em; height: 0.75em; }
.link-box-close { position: absolute; right: 0.75em; top: 0.85em; display: block; }
.link-box-close:hover, .link-box-close:active,  .link-box-close:focus { color: $black; }
.link-box-active { opacity: 1; transition: color 0.3s ease-in; min-height: 17em; max-height: none;  padding: 1.25em 1.5em; color: $black; }

.link-boxes-edit .link-box:not(.link-box-active) { display: none; }

/* Sorting */

.sort-item, .sort-placeholder { display: block; width: 100%; height: 2.5em; }
.sort-item { background: $white; padding: 0.5em 0.75em; color: $black; position: absolute; z-index: 10; }
.sort-item .icon { fill: darken($lightGray, 10%); position: absolute; right: 0.5em; top: 0.5em; width: 1.5em; height: 1.5em; }
.sort-item:hover .icon { fill: darken($darkerYellow, 5%); }
.sort-item:hover { cursor: pointer; background-color: $yellow; }
.sort-placeholder { margin-bottom: 0.5em; overflow: hidden; position: relative; }
.sort-placeholder:after { content: ""; display: block; width: 100%; height: 100%; position: absolute; left: 0; top: 0; border: 2px dashed darken($gray, 25%); z-index: 1; }

.sort-item-anim { opacity: 0; animation-name: sortItem; animation-duration: 0.3s; }
@keyframes sortItem {
    from { opacity: 0; }
    to { opacity: 1; }
}

.sort-items-sorted .sort-item { background-color: $yellow; }
.sort-items-sorted .sort-item .icon { fill: darken($darkerYellow, 5%); }

.sort-percents li { font-size: 1.5em; color: $white; height: 1.67em; margin-bottom: 0.35em; padding-left: 2.5em; }

.banana-slices { position: relative; z-index: 10; transform: translateX(-2em); }
.banana-lines { width: 307px; position: absolute; max-width: none; }
.banana-lines-wrap { position: absolute; left: 0; top: 1.3em; overflow: hidden; width: 307px; height: 407px; transform: translateX(3.5em); transition: width 0.8s ease-in; }
.banana-lines-hidden { width: 0 !important; }

/* Gmap */

.gmap-wrap { margin: 0 -1.5em 0.5em -1.5em; }
.gmap { width: 100%; height: 34em; }
.gmap img { max-width: inherit; }

.marker-wrap, .marker-wrap div { background: $black !important; }
.marker-wrap-outer > div:first-child > div:last-child { background: $black !important; }
.marker-wrap-outer > div:first-child > div:nth-last-child(2) > div div { background: $black !important; }
.marker-content { max-width: 15em; font-size: 16px; background: $black; color: $lightGray; }
.marker-content { h1, h2, h3, h4, h5, h6 { color: $yellow; } }

.popup-contact { background: $white; color: $black; width: 190px; height: 80px; padding: 0.75em; }
.popup-contact ul { margin: 0; }
.popup-contact ul li { padding: 0; background: none; }

/* Pager */

.pager { margin: 1.5em 0; text-align: center; position: relative; }
.pager li { display: inline-block; padding: 0; background: none; }
.pager li a { display: block; width: 36px; height: 36px; line-height: 36px; background: $gray; text-align: center; }
.pager li a.active { background: $gray; color: $white; text-decoration: none; }
.pager .prev { position: absolute; left: 0; top: 0; }
.pager .next { position: absolute; right: 0; top: 0; }
.pager .counter { display: none; }

/* Show/hide boxes */

.sh-item { border: 2px solid $black; margin-bottom: 1.25em; }
.sh-head { font-size: 1.5em; text-align: center; padding: 0.6em 1em 0.6em 3em; margin: 0; position: relative; }
.sh-head-icon { position: absolute; left: 0; top: 0; height: 100%; width: 2.6em; border-right: 2px solid $black; display: inline-block; }
.sh-head-icon .icon { color: $black; fill: $black; height: 1.25em; width: 1.25em; margin: 0.6em; transition: transform 0.3s ease-in; }
.sh-head:hover, .sh-head:active, .sh-head.active { background: $black; color: $white; cursor: pointer; }
.sh-head:hover .sh-head-icon, .sh-head:active .sh-head-icon, .sh-head.active .sh-head-icon { border-color: $yellow; }
.sh-head:hover .icon, .sh-head:active .icon, .sh-head.active .icon { color: $white; fill: $white; }
.sh-head.active .icon { transform: rotate(-180deg); }
.sh-text { padding: 1.75em 2.25em; }
.sh-text iframe { margin: 0 auto; }
.sh-item .btn { color: $black; border-color: $black; font-size: 1em; padding: 0.6em 2em; }
.sh-item .btn:hover, .sh-item .btn:active, .sh-item .btn:focus { color: $white; border-color: $black; background: $black; }

.sandglass-text { margin-bottom: 4em; }
.sandglass-text p, .sandglass-text ul { margin-bottom: 0.75em; }

.box-yellow { background: $yellow; color: $black; }
.box-yellow { h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h6, h7, .h7 { color: $black; } }
.box-text { padding: 1.5em; }

@media screen and (max-width: $lg) {
	.container { padding-left: 4.75em; }
	.link-box { width: 200px; }

	.banana-lines { width: 245px; }
	.banana-lines-wrap { width: 245px; transform: translateX(6em); }
	.banana-slices { width: 175px; }
	
	.header-logo-in .sidenav-list { transform: translateY(3.5em) translateY(-50%); transition: transform 0.2s ease-in; }
	.header-logo-out .sidenav-list { transform: translateY(-50%); transition: transform 0.2s ease-in; }
}

@media screen and (max-width: $md) {
	.gmap-wrap { margin-left: 0; margin-right: 0; }

	.banana-lines { width: 245px; }
	.banana-lines-wrap { width: 245px; transform: translateX(3em); }
	.banana-slices { width: 175px; }
}

@media screen and (max-width: $sm) {
	.story-content { width: 600px; }
	.story-content iframe { width: 600px; height: 330px; }

	.banana-lines { width: 215px; }
	.banana-lines-wrap { width: 210px; transform: translateX(2em); }
	.banana-slices { width: 175px; margin-top: 0.75em; }

	.sandglass { max-height: 350px; }
	.sandglass-text { margin-bottom: 1em; }
}

@media screen and (max-width: 700px) {
	.gmap-wrap { overflow: hidden; }
	.gmap { margin-top: -3.8em; }
}

@media screen and (max-width: $xs) {
	.sidenav { display: none; width: 100%; }

	.menu-open .sidenav { display: block; height: auto; padding-top: 4.5em; }
	.menu-open .sidenav-list { position: relative; width: 100%; transform: none; }
	.menu-open .sidenav-link { height: auto; background: $yellow; }
	.menu-open .sidenav-link:hover, .menu-open .sidenav-link:active { background: $white; }
	.menu-open .sidenav-link:before { display: none; }
	.menu-open .sidenav-link-text { opacity: 1; max-width: none; visibility: visible; background: transparent; position: relative; top: 0; left: 0; transform: none; }
	.menu-open .sidenav-link-text:before { display: none; }

	.container { padding-left: 15px; }

	.stories .story-preview { width: 100%; max-width: 16em; margin: 0 auto; display: block; }
	.story-content { width: 450px; }
	.story-content iframe { width: 400px; height: 200px; }

	.sort-percents li { padding-left: 0.5em; }
	.banana-lines-wrap { display: none; }
	.banana-slices { transform: translateX(-1em); max-width: 140px; }
}

@media screen and (max-width: 640px) {
	.link-box { top: auto; width: 100%; position: relative; max-width: 300px; }
	.link-box-active { margin: 1.5em auto 0 auto; }
	.canvas-wrap { display: none; }
}

@media screen and (max-width: $xxs) {
	.story-content { width: 300px; }
	.story-content iframe { width: 300px; height: 160px; }

	.link-points-img { max-width: 100%; height: auto; }
	.link-point-head { left: 38%; top: 1%; }
	.link-point-chest { left: 33%; top: 20%; }
	.link-point-stomach { left: 29%; top: 28%; }
	.link-point-hips { left: 35%; top: 33%; }
	.link-point-arm { left: 15%; top: 30%; }
	.link-point-basket { left: 70%; top: 25%; }

	.sort-item .icon-move { display: none; }
}

@media screen and (max-width: 400px) {
	.xxs-hidden { display: none; }
}

@media screen and (max-width: $xxxs) {
	.menu-open .sidenav { padding-top: 9.25em; }
}