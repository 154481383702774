.header { width: 100%; height: 4em; padding: 0; color: $black; background: $white; position: fixed; z-index: 400; left: 0; top: 0; }

.header-logo-wrap { position: absolute; left: -2.4em; top: 0.5em; z-index: 450; }
.header-logo { display: block; height: auto; width: 13em; height: 13em; }
.header-logo .st0 { fill:#E70052 !important; color: #E70052; }
.header-logo .st1 { fill:#FFFFFF; }
.header-logo .st2 { fill:#FEC800; }
.header-logo .st3 { font-family:'TSTARPRO-Bold'; }
.header-logo .st4 { font-size:20.7621px; }
.header-logo .st5 { fill-rule:evenodd; clip-rule:evenodd; fill:#4D4D4D; }
.header-logo .st6 { fill-rule:evenodd; clip-rule:evenodd; }
.header-logo .st7 { fill-rule:evenodd; clip-rule:evenodd; fill:#FFFFFF; }

.header-logo-out .header-logo-wrap { margin-top: -10em; transition: margin-top 0.3s ease-in; display: block; }
.header-logo-in .header-logo-wrap { margin-top: 0; transition: margin-top 0.3s ease-out; }

/* Main navigation */

.main-nav { margin: 0; position: absolute; right: 0; top: 0; }
.main-nav ul { margin: 0; }
.main-nav ul li { padding: 0; margin: 0; background: none; display: inline-block; position: relative; vertical-align: top; }
.main-nav ul li a { text-align: center; text-decoration: none; color: $black; line-height: 4em; height: 4em; width: 4em; border-left: 1px solid $lightGray; display: block; white-space: nowrap; padding: 0 0.75em; }
.main-nav ul li a:hover, .main-nav ul li a:active, .main-nav ul li a:focus { color: $black; }
.main-nav ul li.active a { color: $black; }
.main-nav ul li .main-nav-btn { background: $pink; padding: 0 1em; color: $white; border: none; transition: all 0.2s ease-in; width: auto; font-size: 1.5em; height: 2.66666em; line-height: 2.66666em; }
.main-nav ul li .main-nav-btn:hover { background: $yellow; color: $black; }
.main-nav-icon { display: inline-block; width: 1.25em; height: 1.25em; transform: translateY(0.2em); }

.earnings { position: absolute; right: 15.3em; top: 0; width: 24.5em; font-size: 1.5em; max-height: 2.66666em; z-index: 500; overflow: hidden; transition: max-height 0.3s ease-in; }
.earnings ul { line-height: 1em; padding: 0.5em 0.5em; margin: 0; }
.earnings ul li { display: inline-block; border-left: 1px solid $lightGray; padding: 0 0.5em; margin: 0; height: 1.66666em; width: 4.35em; vertical-align: top; }
.earnings ul li:first-of-type { border-left: none; padding-left: 2.5em; width: 9em; }
.earnings ul li.earnings-small  { width: 3.5em; }
.earnings-icon { position: absolute; left: 1em; top: 0.5em; width: 1.5em; height: 1.5em; fill: $yellow; }
.earnings-count { display: block; height: 1em; white-space: nowrap; }
.earnings-label { font-size: 0.5em; display: block; color: $gray; line-height: 1.6; white-space: nowrap; }
.earnings-label-more { display: none; }
.earnings-label.color-black { color: $black; }

.earnings .earnings-trig, .earnings .earnings-trig:focus { font-size: 0.66666em; display: inline-block; width: 2.5em; height: 2.5em; border-radius: 50%; padding: 0; text-align: center; line-height: 1.7; border: 2px solid $lightGray; min-width: 0; position: absolute; right: 0.75em; top: 0.75em; transition: all 0.2s ease-in; }
.earnings .earnings-trig:hover, .earnings .earnings-trig:active { background: $yellow; border-color: $yellow; color: $black; }
.earnings .earnings-trig:before { content: "?"; font-size: 1.25em; display: inline-block; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

.earnings.active { max-height: 1000px; height: auto; background: $yellow; }
.earnings.active ul { height: auto; padding-bottom: 0; border-bottom: 1px solid $darkerYellow; }
.earnings.active ul li  { border-color: $darkerYellow; min-height: 3.75em; height: auto; padding-bottom: 0.5em; }
.earnings.active .earnings-trig, .earnings.active .earnings-trig:hover { border: 2px solid $white; }
.earnings.active .earnings-trig:before { content: "×"; font-size: 1.4em; margin-top: -0.1em; }
.earnings.active .earnings-icon { fill: #fff; }
.earnings.active .earnings-label  { color: $darkYellow; display: none; }
.earnings.active .earnings-label-more { display: block; }
.earnings.active .earnings-label { white-space: normal; }

.earnings-text { font-size: 0.66666em; padding: 1em 1.5em; opacity: 0; width: 100%; }
.earnings.active .earnings-text { opacity: 1; }

.lngs-list, .snets-list { position: absolute; opacity: 0; max-height: 0; overflow: hidden; transition: max-height 0.3s ease-in, opacity 0.1s ease-in; left: 0; top: 100%; }

.lngs-list { width: 10.5em; }
.lngs .lngs-list li a { height: 2.5em; line-height: 2.5; width: 3.5em; border-top: 1px solid $lightGray; background: $white; }
.lngs .lngs-list li:nth-child(3n-2) a { border-left: none; }
.lngs .icon-dropdown { display: inline-block; width: 0.6em; height: 0.6em; margin: 1.75em -0.75em 0 0.2em; }

.lngs-list.active, .snets-list.active { opacity: 1; max-height: 1000px; }

.snets-list { background: $white; }
.main-nav .snets-list li { border-top: 1px solid $lightGray; width: 100%; }
.main-nav .snets-list li a { height: 2.5em; line-height: 2.5; width: auto; background: $white; border-left: none; }

.main-nav-trig.active { background: $yellow; border: none; }

.mobile-nav-trig { border: none; position: absolute; top: 0; right: 0.5em; display: none; text-align: center; padding: 0.5em; z-index: 1100; }
.mobile-nav-trig .icon { width: 3em; height: 3em; fill: $yellow; }

@media screen and (max-width: $md) {
	
}

@media screen and (max-width: $sm) {
	.earnings { right: 4em !important; }
	.header { position: relative; font-size: 1.15em; }
	.mobile-nav-trig { display: block; transition: right 0.3s ease-in; }
	.main-nav { background: $white; z-index: 1000; display: block; width: 100%; text-align: right; top: 0; right: 0; position: absolute; transform: translateX(100%); transition: transform 0.3s ease-in; }

	.header-logo-wrap { left: -1em; transition: left 0.3s ease-in; }
	.header-logo { width: 10em; height: 10em; }
	
	.menu-open .mobile-nav-trig { right: auto; left: 0.5em; }
	.menu-open .main-nav { left: 0; transform: translateX(0); }
	.menu-open .header-logo-wrap { left: -10em; }
}

@media screen and (max-width: $xs) {
	.earnings { display: none; }
}

@media screen and (max-width: $xxxs) {
	.menu-open .header { height: 8em; }
	.menu-open .mobile-nav-trig { right: auto; left: 0.5em; }

	.main-nav { padding-top: 4em; }
	.main-nav ul li .main-nav-btn { position: absolute; right: 0; top: -2.66666em; width: 8em; }

	.lngs-list, .snets-list { transform: translateX(-50%); }
}
