.slider ul, .slider li.panel { margin: 0; padding: 0; list-style: none; position: relative; display: block; }
.slider, .slider li.panel { overflow: hidden; background: none; }

.slider-nav { margin-top: 1em; display: block; text-align: center; width: 100%; }
.slider-nav ol { margin: 0 0 0 -10px; }
.slider-nav li { margin: 0 0 0 10px; padding: 0; background: none; display: inline-block; font-size: 0; }
.slider-nav li:before { content: none; }
.slider-nav li a { width: 8px; height: 8px; display: block; background: #2f4e75; text-indent: -80000px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.slider-nav li.current a { background: #b8bdc5; }

.slider-nav .prev { position: absolute; left: 3.5em; top: 50%; display: block; width: 3.25em; height: 3.25em; border-radius: 50%; border: 0.1em solid $white; background: transparent; transition: background 0.1s ease-in; }
.slider-nav .prev:after { content: ""; position: absolute; display: block; width: 0.85em; height: 0.85em; border-left: 0.1em solid $white; border-bottom: 0.1em solid $white; transform: translate(1.2em, 1.1em) rotate(45deg); margin-right: 0.5em; display: block; }
.slider-nav .prev:hover { border-color: $yellow; background: $yellow; }
.slider-nav .prev:hover:after { border-color: $black; }
.slider-nav .next { position: absolute; right: 3.5em; top: 50%; display: block; width: 3.25em; height: 3.25em; border-radius: 50%; border: 0.1em solid $white; background: transparent; transition: background 0.1s ease-in; }
.slider-nav .next:after { content: ""; position: absolute; display: inline-block; width: 0.85em; height: 0.85em; border-right: 0.1em solid $white; border-top: 0.1em solid $white; transform: translate(0.9em, 1.1em) rotate(45deg); margin-right: 0.5em; display: block; }
.slider-nav .next:hover { border-color: $yellow; background: $yellow; }
.slider-nav .next:hover:after { border-color: $black; }

/* Next slides hidden before sudoslider init */
.slider .panel:not(:first-of-type) { display: none; }
.slider .panel.slide { display: block; }

/* Gallery slider */

.gallery-slider .gallery-item { position: relative; }
.gallery-slider figcaption { opacity: 0; transition: opacity 0.2s ease-in; display: block; width: 100%; padding: 2em 7em; background: rgba($black, 0.5); position: absolute; left: 0; bottom: 0; color: $white; }
.gallery-slider .slide:hover figcaption, .gallery-slider .slide:active figcaption { opacity: 1; }
.gallery-item iframe { width: 100%; height: 635px; }

/* Stories slider*/
.story-slider .slider-nav { margin: 0; }
.story-slider iframe { width: 773px; max-width: 100%; height: 460px; display: block; }
.story-slider-img p { margin-bottom: 0; }
.story-slider .slider-nav .prev { left: -3.5em; }
.story-slider .slider-nav .next { right: -3.5em; }

@media screen and (max-width: $lg) {
	.gallery-item iframe { height: 495px; }
}

@media screen and (max-width: $md) {
	.gallery-item iframe { height: 375px; }
}

@media screen and (max-width: $sm) {
	
}

@media screen and (max-width: $xs) {
	.gallery-item iframe { height: 265px; }
	.story-slider { padding-left: 4em; padding-right: 4em; }
	.story-slider .slider-nav .prev { left: 0; }
	.story-slider .slider-nav .next { right: 0; }
}

@media screen and (max-width: $xxs) {
	
}